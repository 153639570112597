// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Content_box__zpia1 {\n    position: relative;\n    background: #ffffff;\n    z-index: 10;\n    \n    width: 800px;\n    height: 100%;\n\n    text-align: left;\n}", "",{"version":3,"sources":["webpack://./src/component/Content.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mBAAmB;IACnB,WAAW;;IAEX,YAAY;IACZ,YAAY;;IAEZ,gBAAgB;AACpB","sourcesContent":[".box {\n    position: relative;\n    background: #ffffff;\n    z-index: 10;\n    \n    width: 800px;\n    height: 100%;\n\n    text-align: left;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "Content_box__zpia1"
};
export default ___CSS_LOADER_EXPORT___;
