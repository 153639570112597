// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".FakeMenu_box__Ken1m {\n    position: relative;\n    z-index: 15;\n    width: 250px;\n    height: 10px;\n}", "",{"version":3,"sources":["webpack://./src/component/FakeMenu.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,WAAW;IACX,YAAY;IACZ,YAAY;AAChB","sourcesContent":[".box {\n    position: relative;\n    z-index: 15;\n    width: 250px;\n    height: 10px;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "FakeMenu_box__Ken1m"
};
export default ___CSS_LOADER_EXPORT___;
