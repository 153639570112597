// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Side_box__b0mmr {\n    position: relative;\n    background: rgba(81, 81, 81, 0.747);\n    z-index: 15;\n    width: 10px;\n    height: 100%;\n}", "",{"version":3,"sources":["webpack://./src/component/Side.module.css"],"names":[],"mappings":"AAAA;IACI,kBAAkB;IAClB,mCAAmC;IACnC,WAAW;IACX,WAAW;IACX,YAAY;AAChB","sourcesContent":[".box {\n    position: relative;\n    background: rgba(81, 81, 81, 0.747);\n    z-index: 15;\n    width: 10px;\n    height: 100%;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"box": "Side_box__b0mmr"
};
export default ___CSS_LOADER_EXPORT___;
